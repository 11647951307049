<template>
  <div class="app-calendar overflow-hidden border">
    <modal v-if="alert" :show="showModalAlert" :alert="alert" @update="initialize" @close="showModalAlert = false"/>
    <ModalCampaign v-if="campaign" :show="showModalCampaign" :campaign="campaign" :template="template" @update="initialize" @close="showModalCampaign = false" />
    <div class="row no-gutters">
      <!-- Sidebar -->
      <!-- <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div> -->

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <!-- <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              :events="events"
              class="full-calendar"
            /> -->
            <FullCalendar
              :options="opts"
              class="full-calendar"
              :selectable="true"
              @select="handleSelect"
              @clickDate="handleSelect"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <!-- <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      /> -->
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
// import store from '@/store'
// import { onUnmounted } from '@vue/composition-api'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import Modal from '@/components/alert/Modal.vue'
import ModalCampaign from '@/components/campaign/Modal.vue'
// import calendarStoreModule from './calendarStoreModule'
// import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
// import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
// import useCalendar from './useCalendar'

export default {
  components: {
    Modal,
    FullCalendar,
    ModalCampaign, // make the <FullCalendar> tag available
    // CalendarSidebar,
    // CalendarEventHandler,
  },
  data() {
    return {
      template: '',
      alerts: [],
      campaigns: [],
      alert: null,
      showModalAlert: false,
      campaign: null,
      showModalCampaign: false,
      opts: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        selectable: true,
        dateClick: this.handleSelect,
        eventClick: this.handleSelect,
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        events: [
        ],
      },
    }
  },
  async mounted() {
    try {
      await this.initialize()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async initialize() {
      try {
        this.opts.events = []
        await this.getAlerts()
        await this.getCampaigns()
        this.showModalAlert = false
        this.showModalCampaign = false
      } catch (err) {
        console.log(err)
      }
    },
    handleSelect(value) {
      const event = value.event?._def?.extendedProps
      if (event.type === 'alert') {
        this.alert = this.alerts.find(a => a._id === event._id)
        this.showModalAlert = true
      } else if (event.type === 'campaign') {
        this.campaign = this.campaigns.find(a => a.id === event._id)
        this.getCampaign()
      }
    },
    async getCampaign() {
      try {
        const { campaign } = await this.$http.get(`/admin/campaigns/${this.campaign.id}`)
        this.campaign = { campaign: { ...this.campaign }, ...campaign }
        const template = await this.$http.get(`/admin/campaigns/${campaign?.attributes?.content?.template_id}/template`)
        this.template = template
        this.showModalCampaign = true
      } catch (err) {
        console.log(err)
      }
    },
    async getAlerts() {
      try {
        const { alerts } = await this.$http.get(`/admin/alerts/?country=${this.country}`)
        this.alerts = alerts
        this.opts.events.push(...alerts.map(a => ({
          title: `${a.title} - Notification`, start: a.dateStart, end: a.dateEnd, textColor: 'white', backgroundColor: this.getRandomColor(), type: 'alert', _id: a._id,
        })))
      } catch (err) {
        console.log(err)
      }
    },
    async getCampaigns() {
      try {
        const { campaigns } = await this.$http.get(`/admin/campaigns/?country=${this.country}`)
        this.campaigns = campaigns
        this.opts.events.push(...campaigns.map(a => ({
          title: `${a.attributes.name} - Email (${a.attributes.status})`, type: 'campaign', _id: a.id, date: a.attributes.send_time, textColor: 'white', backgroundColor: this.getRandomColor(),
        })))
      } catch (err) {
        console.log(err)
      }
    },
    getRandomColor() {
      return `#${Math.floor((Math.random() * 16777215)).toString(16)}`
    },
  },
  // setup() {
  //   const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

  //   // Register module
  //   if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

  //   // UnRegister on leave
  //   onUnmounted(() => {
  //     if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
  //   })

  //   const {
  //     refCalendar,
  //     isCalendarOverlaySidebarActive,
  //     event,
  //     clearEventData,
  //     addEvent,
  //     updateEvent,
  //     removeEvent,
  //     fetchEvents,
  //     refetchEvents,

  //     // ----- UI ----- //
  //     isEventHandlerSidebarActive,
  //   } = useCalendar()

  //   fetchEvents()
  //   const calendarOptions = ({
  //     // plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
  //     // initialView: 'dayGridMonth',
  //     // headerToolbar: {
  //     //   start: 'sidebarToggle, prev,next, title',
  //     //   end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
  //     // },
  //     events,
  //   })
  //   return {
  //     refCalendar,
  //     isCalendarOverlaySidebarActive,
  //     event,
  //     clearEventData,
  //     addEvent,
  //     updateEvent,
  //     removeEvent,
  //     refetchEvents,
  //     calendarOptions,
  //     events,

  //     // ----- UI ----- //
  //     isEventHandlerSidebarActive,
  //   }
  // },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
