<template>
  <div>
    <b-modal
      v-model="show"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      lazy
      static
      @hidden="$emit('close')"
    >
      <div>
        <div>
          <div>
            <b-row class="breadcrumbs-top mb-2">
              <b-col cols="6">
                <h4>{{ campaign.attributes.label }}</h4>
              </b-col>
              <b-col cols="3">
                <b-badge
                  pill
                  variant="light-primary"
                >
                  {{ campaign.campaign.attributes.status }}
                </b-badge>
              </b-col>
              <b-col cols="3">
                <p>Envoyé à {{ $moment(campaign.campaign.attributes.send_time).format('DD/MM/YYYY à hh:mm') }}</p>
              </b-col>
            </b-row>
          </div>
          <b-row class="breadcrumbs-top">
            <b-col cols="6">
              <p><u><strong>Sujet:</strong></u> {{ campaign.attributes.content.subject }}</p>
            </b-col>
            <b-col cols="6">
              <p><u><strong>Preview Text:</strong></u>{{ campaign.attributes.content.preview_text }}</p>
            </b-col>
          </b-row>
          <b-row class="breadcrumbs-top">
            <b-col cols="6">
              <p><u><strong>From email:</strong></u> {{ campaign.attributes.content.from_email }}</p>
            </b-col>
            <b-col cols="6">
              <p><u><strong>From label:</strong></u> {{ campaign.attributes.content.from_label }}</p>
            </b-col>
          </b-row>
        </div>
        <p>Preview:</p>
        <div v-html="template" />

      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BBadge,
  // BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    template: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

  <style>
   .jsoneditor-poweredBy {
     display: none;
   }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
