<template>
  <div>
    <b-modal
      v-model="show"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      lazy
      static
      @hidden="$emit('close')"
    >
      <div>
        <!-- <v-jsoneditor
            v-model="blocs"
            :options="options"
            height="600px"
            @error="errorJson"
          /> -->
        <quill-editor-snow style="display: none;" />
        <app-collapse>
          <app-collapse-item title="Contenu">
            <b-row>
              <b-col :md="6">
                <p>Titre:</p>
                <b-form-input
                  v-model="alert.title"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="6">
                <p>Type:</p>
                <b-form-input
                  v-model="alert.type"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col
                :md="6"
                class="my-3"
              >
                <p>Description:</p>
                <quill-editor
                  v-model="alert.description"
                  :options="snowOption"
                  style="width: 100%"
                />
              </b-col>
              <b-col
                :md="6"
                class="my-3"
              >
                <p>Lien de l'image:</p>
                <b-form-input
                  v-model="alert.imgSrc"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="6">
                <p>Titre du bouton:</p>
                <b-form-input
                  v-model="alert.buttonTitle"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col :md="6">
                <p>Lien du bouton:</p>
                <b-form-input
                  v-model="alert.buttonLink"
                  class="mr-4"
                  style="width: 100%"
                />
              </b-col>
              <b-col
                :md="6"
                class="my-3"
              >
                <p>Date de début d'affichage:</p>
                <flat-pickr
                  v-model="alert.dateStart"
                  :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
                  placeholder="Date de début"
                  class="form-control"
                />
              </b-col>
              <b-col
                :md="6"
                class="my-3"
              >
                <p>Date de début de fin:</p>
                <flat-pickr
                  v-model="alert.dateEnd"
                  placeholder="Date de fin"
                  :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
                  class="form-control"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                :md="6"
                class="my-3"
              >
                Preview
                <b-card>
                  <b-card-body>
                    <div class="block">
                      <img
                        class="w-full object-cover object-center"
                        style="height: 300px !important;"
                        :src="alert.imgSrc"
                      >
                      <div class="relative w-full mt-2">
                        <div>
                          <div class="flex justify-between items-center px-4">
                            <p class="text-grey-300 font-bold ">
                              {{ alert.type }}
                            </p>
                          </div>
                          <div class="px-12">
                            <p class="text-green-500 font-title text-normal mt-4">
                              {{ alert.title }}
                            </p>
                            <p
                              class="text-green-500 mt-2"
                              v-html="alert.description"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <b-row
          v-if="alert.title && alert.type && alert.description && alert.imgSrc && alert.buttonTitle && alert.dateStart && alert.dateEnd"
          class="my-2"
        >
          <b-col
            span="12"
            class="primary d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="updateValue()"
            >
              <span class="text-nowrap">Enregistrer</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BCard,
  BCardBody,
  // BFormTextarea,
} from 'bootstrap-vue'
import QuillEditorSnow from '@core/components/quill-editor/QuillEditorCustom.vue'
import { Quill, quillEditor } from 'vue-quill-editor'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    QuillEditorSnow,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BCard,
    BCardBody,
    // BFormTextarea,
    quillEditor,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  props: {
    alert: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alerts: [],
      showModal: false,
      options: { mode: 'code' },
      blocs: [],
      productSelect: [],
      productId: null,
      firstContent: '',
      country: 'FR',
      countryOptions: ['ES', 'FR', 'DE', 'IT', 'NL', 'EN'],
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
        },
      },
      whithoutOption: {
        theme: 'snow',
        modules: {
          toolbar: [],
        },
      },
    }
  },
  async mounted() {
    try {
      const Block = Quill.import('blots/block')
      Block.tagName = 'div'
      Quill.register(Block, true)
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    convertHtml(html) {
      // eslint-disable-next-line newline-per-chained-call
      return html?.replaceAll('<span style="color: rgb(0, 0, 0);">', '')?.replaceAll('</span>', '')?.replaceAll('style="color: rgb(0, 0, 0);"', '')
    },
    async updateValue() {
      try {
        this.alert.dateStart = this.$moment(this.alert.dateStart)
        this.alert.dateStartUnix = this.$moment(this.alert.dateStart).toDate().getTime()
        this.alert.dateEnd = this.$moment(this.alert.dateEnd)
        this.alert.dateEndUnix = this.$moment(this.alert.dateEnd).toDate().getTime()
        await this.$http.post(`/admin/alerts/?country=${this.country}`, { alert: this.alert })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$emit('update')
      } catch (err) {
        console.log(err)
      }
    },
    errorJson(err) {
      console.log(err)
    },
  },
}
</script>

  <style>
   .jsoneditor-poweredBy {
     display: none;
   }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
